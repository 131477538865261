<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left spot">
          <div class="kw-back">
            <h3>{{ $t("APPS.CALENDAR_MODULE") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("APPS.CALENDAR_MODULE") }}</span>
              </li>
              <!-- <li>
                <span>{{ $t("COMMON.CALENDARS_LIST") }}</span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <div v-if="globalOrganization?.id">
        <calendar-list-table :filter-organization="globalOrganization?.id" />
      </div>
      <select-an-organization-message v-else />
    </div>
  </div>
</template>
<script>
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import CalendarListTable from "./partials/CalendarListTable.vue";
import SelectAnOrganizationMessage from "@/components/SelectAnOrganizationMessage.vue";
import { mapGetters } from "vuex";

export default {
  layout: "DashboardLayout",

  components: {
    SelectAnOrganizationMessage,
    [Button.name]: Button,
    CalendarListTable,
  },

  mixins: [requestErrorMixin],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
    ...mapGetters("organizationOptions", {
      organizationConfig: "organizationConfig",
    }),
  },

  watch: {},

  data() {
    return {
      loading: false,
    };
  },

  mounted() {},

  methods: {},
};
</script>
